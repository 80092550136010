import styled from 'styled-components';
import { IndexLayout } from '../layouts';
import { NewAppTitle, NewAppText, NewAppButton } from '../components/ui';
// import { ThankYou, ThankYouHeader, ReturnToHomePage } from '../assets';
// import { device } from '../utils/variables';
import { Link } from 'gatsby';
import { theme } from '../utils/theme';
import { mobileSize } from '../utils';

export default () => {
  return (
    <IndexLayout>
      <Container>
        <Main>
          <Header>Thank You</Header>
            <ThankYouText>
              Thank you for contacting Rapptr. One of our account executives will be following up
              within 24-48 hours.
            </ThankYouText>
          <ButtonDiv>
            <Link to="/" aria-label="rapptr">
              <NewAppButton>
                Return to Homepage
              </NewAppButton>
            </Link>
          </ButtonDiv>
        </Main>
      </Container>
    </IndexLayout>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 3rem;
  background-color: ${theme.BACKGROUND_COLOR};
  font-family: ${theme.PRIMARY_FONT};
  color: ${theme.WHITE_COLOR};
`;

const HeaderImageDiv = styled.div`
  height: 250px;
  overflow: hidden;
  position: relative;
  width: 100%;
  @media ${mobileSize} {
    height: 500px;
  }
`;

const StyledHeaderImage = styled.img`
  max-width: 100%;
  min-height: 100%;
  object-fit: cover;
`;

const StyledHeaderTitle = styled.img`
  position: absolute;
  left: 50%;
  top: 50%;
  /* margin-left: -25%; */
  z-index: 3;
  transform: translateX(-50%) translateY(-50%);
  width: 75%;

  @media ${mobileSize} {
    width: 45%;
  }
`;

const Header = styled(NewAppTitle)`
  margin-top: 10rem;
  font-family: ${theme.PRIMARY_FONT};
  font-size: 6rem;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
`;

const Main = styled.div`
  width: 40%;
  min-width: 500px;
  text-align: center;
  font-family: ${theme.PRIMARY_FONT};

  & > * {
    margin-bottom: 6rem;
  }

  @media ${mobileSize} {
    width: 90%;
    min-width: 100px;
  }
`;

const ThankYouDiv = styled.div`
  width: 100%;
  margin: 100px 0px;
  @media ${mobileSize} {
    margin: 200px 0px;
  }
`;
const ThankYouText = styled(NewAppText)`
  font-size: 22px;
  line-height: 26px;
  text-align: center;
`;

const ButtonDiv = styled.div`
  display: flex;
  justify-content: center;
  @media ${mobileSize} {
    margin: 200px 0px;
  }
`;
